import axios from "axios";
import { useSelector } from "react-redux"; // Import the useSelector hook

const API = axios.create({
    // baseURL: "https://app.tradeyaar.in/api",
    baseURL: "https://app.bullex.in/api",
    // baseURL: "https://appalphastock.bullex.in/api",


});

export default API;
